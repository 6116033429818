<template>
  <ion-page>
    <Header type="0" title="叉车信息" v-if="menuRole == 'phone'"> </Header>
    <ion-content scroll-y="true" id="ProjectMainProjectListOut">
      <div class="root">
        <div class="item">
          <ion-text class="left"> 调度人</ion-text>
          <div class="right">
            <ion-text>{{ formData.dispatcher }}</ion-text>
          </div>
        </div>
        
        <div class="item">
          <div class="item remark">
            <ion-text class="left"> 用车事由</ion-text>
            <div style="max-height:150px;width:100%;overflow:auto">
             <ion-textarea
              auto-grow="true"
              class="right"
              placeholder="请输入..."
              maxlength="500"
              v-model="formData.reason"
              :disabled="true"
            ></ion-textarea>
           </div>
          </div>
        </div>
        <div class="item">
          <div class="left">紧急程度</div>
          <div class="right">
            <ion-text>{{ columns[formData.urgencyId-1] }}</ion-text>
          </div>
        </div>
        <div class="item">
            <ion-text class="left"> 开始时间</ion-text>
            <div class="right">
              <ion-text>{{ formData.beginTime ? formatDate(formData.beginTime) : "--" }}</ion-text>
            </div>
          </div>
          <div class="item">
            <ion-text class="left"> 结束时间</ion-text>
            <div class="right">
              <ion-text>{{ formData.endTime ? formatDate(formData.endTime) : "--" }}</ion-text>
            </div>
          </div>
          <div class="item">
          <div class="left">手机号</div>
          <div class="right">
            <ion-text>{{ formData.applyPersonPhone }}</ion-text>
          </div>
        </div>
        <div class="item" @click="openChooseOrganizationModal" :hidden="formData.stateId != 4 || formData.isFinished == 1||formData.dispatcherId!=userId">
          <div class="left"><span class="required">*</span>司机</div>
          <div class="right">
            <ion-text>{{ formData.forkliftDriver }}</ion-text>
            <span class="iconfont icon-jinru"></span>
          </div>
        </div>
            <div class="item"  v-if="formData.isFinished == 1">
          <div class="left">司机</div>
          <div class="right">
            <ion-text>{{ formData.forkliftDriver }}</ion-text>
          </div>
        </div>
     
        <div class="item" v-if="formData.stateCN">
          <div class="left">状态</div>
          <div class="right">
            <ion-text>{{ formData.stateCN }}</ion-text>
          </div>
        </div>
        <div class="item" v-if="(formData.stateId == 4 && formData.dispatcherId==userId)?true:formData.rejectInfo">
          <div class="item remark">
            <div class="left"><span class="required">*</span>驳回理由</div>
            <div style="max-height:150px;width:100%;overflow:auto">
             <ion-textarea
              auto-grow="true"
              class="right"
              :placeholder="(formData.stateId == 4 && formData.dispatcherId==userId)?'请输入...':''"
              maxlength="500"
              v-model="formData.rejectInfo"
              :disabled="!(formData.stateId == 4 && formData.dispatcherId==userId)"
            ></ion-textarea>
           </div>
          </div>
        </div>
        <div class="item">
          <div class="item remark">
            <ion-text class="left">用车图片</ion-text>
            <div style="width: 100%">
              <upload-img
                @modelBack="modelBack"
                :maxLength="formData.imageUrl?formData.imageUrl.length:0"
                :form="formData"
                photo="imageUrl"
                :canRemove="false"
                :id="formData.id"
              ></upload-img>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <person-choose @chooseCode="searchPerson" :id="formData.id"></person-choose>
    <ion-footer>
    
      <ion-button
       v-if="(formData.stateId == 1 && formData.applyPersonId==userId) || (formData.stateId == 4 && formData.dispatcherId==userId)"
        expand="block"
        :hidden="formData.isFinished == 1"
        @click="commit"
        >提交</ion-button
      >

      <ion-button
          expand="block"
          :hidden="!(formData.stateId == 2 && formData.forkliftDriverId==userId)"
          @click="close"
          >关闭</ion-button>
          <ion-button
          expand="block"
          :hidden="!(formData.stateId == 4 && formData.dispatcherId==userId)"
          @click="reject"
          >驳回</ion-button>
    </ion-footer>
  </ion-page>
</template>
<script>
import Header from "@/components/Header.vue";
import * as dd from "dingtalk-jsapi";
import moment from "moment";

import {
  getForklift,
  newForklift,
  sendForklift,
  closeForklift,
} from "@/api/carManage/vehicleApplication";
import { menuController, loadingController, alertController } from "@ionic/vue";
import personChoose from "./driverChoose.vue";
import SystemUtils from "@/utils/SystemUtils";

import uploadImg from "@/components/getPhotoVehicle/index.vue";
import Utils from "@/utils/Utils.ts";
export default {
  name: "vehicleApplication",
  components: {
    uploadImg,
    Header,
    personChoose,
  },
  data() {
    return {
      formData: {
        imageUrl:[]
      },
      showPicker: false,
      menuRole: "phone",
      columns: ["低(4小时内叉车开始作业)", "中(2小时内叉车开始作业)", "高(1小时内叉车开始作业)"],
      userId:''
      
    };
  },
  mounted() {
    this.menuRole = localStorage.getItem("menuRole");
    if (this.menuRole == "dd") {
      dd.ready(function () {
        dd.biz.navigation.setRight({
          show: false, //控制按钮显示， true 显示， false 隐藏， 默认true
          control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
          text: "发送", //控制显示文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            //如果control为true，则onSuccess将在发生按钮点击事件被回调
            /*
        {}
        */
          },
          onFail: function (err) {},
        });
        dd.biz.navigation.setTitle({
          title: "叉车信息", //控制标题文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            /*结构
        {
        }*/
          },
          onFail: function (err) {},
        });
      });
    }
    this.userId = SystemUtils.loginUser.id
    this.getForklift(this.$route.query.id);
  },
  methods: {
    modelBack(data){
      this.formData.imageUrl = data;
      console.log(this.formData.imageUrl, 4424232);
    },
    formatDate(time) {
        return moment(time).format("YYYY-MM-DD HH:mm");
    },
    //清空时间
    resetTime(type) {
      if (type == "beginTime") {
        this.formData.beginTime = "";
      } else if (type == "endTime") {
        this.formData.endTime = "";
      }
    },
    // 查看用车
    getForklift(id) {
      getForklift({ id: id }).then((res) => {
        this.formData = res.data.data.resultData || {};
        this.formData.imageUrl = this.formData.imageUrl||[];
        this.formData.type = 2;
      });
    },
    //选择级别
    onConfirm(value, index) {
      var _this = this;
      switch (index) {
        case 0:
          _this.formData.urgencyId = 1;
          _this.formData.urgency = "低(4小时内叉车开始作业)";
          break;
        case 1:
          _this.formData.urgencyId = 2;
          _this.formData.urgency = "中(2小时内叉车开始作业)";
          break;
        case 2:
          _this.formData.urgencyId = 3;
          _this.formData.urgency = "高(1小时内叉车开始作业)";
          break;

        default:
          _this.formData.urgencyId = 1;
          _this.formData.urgency = "低(4小时内叉车开始作业)";
          break;
      }
      this.showPicker = false;
    },
    openChooseOrganizationModal() {
      menuController.enable(true, "searchPerson");
      menuController.open("searchPerson");
    },
    //选择调度人
    searchPerson(val) {
      this.formData.forkliftDriverId = val.id;
      this.formData.forkliftDriver = val.nameCN;
      this.formData.forkliftDriverPhone = val.telephone;
    },
    // 提交
    async commit() {
      const _this = this;
      if (!_this.formData.forkliftDriverId) {
        Utils.presentToastTopWarning("请选择司机", "danger");
        return false;
      };
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "提示",
        message: "是否提交",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async () => {
              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });
              await loading.present();
              _this.formData.userId = SystemUtils.loginUser.id;
              _this.formData.nameCN = SystemUtils.loginUser.name;
              sendForklift(Object.assign({},_this.formData,{agentId:localStorage.getItem('agentId')})).then((res) => {
                loading.dismiss();
                if (!res.data.isError && res.data.data.isSuccess) {
                  Utils.presentToastTopWarning("提交成功", "success");
                  _this.$router.go(-1);
                } else {
                  Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "danger"
                  );
                }
              });
            },
          },
        ],
        ionAlertDidPresent: (e) => {
          console.log(e);
        },
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    // 关闭
    async close() {
        const _this = this;
        // if (!_this.formData.forkliftDriverId) {
        //   Utils.presentToastTopWarning("请选择司机", "danger");
        //   return false;
        // }
        const alert = await alertController.create({
          cssClass: "my-custom-class",
          header: "提示",
          message: "是否关闭",
          buttons: [
            {
              text: "取消",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                console.log("Confirm Cancel");
              },
            },
            {
              text: "确定",
              handler: async () => {
                const loading = await loadingController.create({
                  cssClass: "my-custom-class",
                  message: "请稍等...",
                  duration: 50000,
                });
                await loading.present();
                _this.formData.userId = SystemUtils.loginUser.id;
                _this.formData.nameCN = SystemUtils.loginUser.name;
                closeForklift({id:_this.formData.id,forkliftDriverId:_this.formData.forkliftDriverId,forkliftDriver:_this.formData.forkliftDriver,agentId:localStorage.getItem('agentId')}).then((res) => {
                  loading.dismiss();
                  if (!res.data.isError && res.data.data.isSuccess) {
                    Utils.presentToastTopWarning("关闭成功", "success");
                    _this.$router.go(-1);
                  } else {
                    Utils.presentToastTopWarning(
                      res.data.message || res.data.data.message,
                      "danger"
                    );
                  }
                });
              },
            },
          ],
          ionAlertDidPresent: (e) => {
            console.log(e);
          },
        });
        await alert.present();
  
        const { role } = await alert.onDidDismiss();
        console.log("onDidDismiss resolved with role", role);
      },

      async reject(){
        const _this = this;
        if (!_this.formData.rejectInfo) {
          Utils.presentToastTopWarning("请输入驳回理由", "danger");
          return false;
        }

        const alert = await alertController.create({
          cssClass: "my-custom-class",
          header: "提示",
          message: "是否驳回",
          buttons: [
            {
              text: "取消",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                console.log("Confirm Cancel");
              },
            },
            {
              text: "确定",
              handler: async () => {
                const loading = await loadingController.create({
                  cssClass: "my-custom-class",
                  message: "请稍等...",
                  duration: 50000,
                });
                await loading.present();
                _this.formData.userId = SystemUtils.loginUser.id;
                _this.formData.nameCN = SystemUtils.loginUser.name;
                sendForklift(Object.assign({},_this.formData,{agentId:localStorage.getItem('agentId')},{type:3})).then((res) => {
                  loading.dismiss();
                  if (!res.data.isError && res.data.data.isSuccess) {
                    Utils.presentToastTopWarning("驳回成功", "success");
                    _this.$router.go(-1);
                  } else {
                    Utils.presentToastTopWarning(
                      res.data.message || res.data.data.message,
                      "danger"
                    );
                  }
                });
              },
            },
          ],
          ionAlertDidPresent: (e) => {
            console.log(e);
          },
        });
        await alert.present();
  
        const { role } = await alert.onDidDismiss();
        console.log("onDidDismiss resolved with role", role);
      }
  },
};
</script>
<style lang="scss" scoped>
.item {
  justify-content: space-between;
}
ion-button {
  --background: #00529c;
}

.datetime-placeholder,
.picker {
  padding: 0 1rem 0 0;
}
.placeholdercolor {
  --placeholder-color: #cbcbce;
  width: calc(100% - #{px2rem(250)});
  padding: 0 1rem 0 0;
  text-align: right;
}
.root .remark .right{
  width: 100%;
}
</style>